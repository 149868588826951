import axios from "axios";
import { FC, useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import PageHeader from "../../../../layout/components/content/PageHeader";
import QueryBuilder from "./QueryBuilder";
import { BootstrapConfig } from "@react-awesome-query-builder/bootstrap";
import type { Config } from "@react-awesome-query-builder/bootstrap";
import FieldTypeConvert from "../../core/FieldTypeConvert";
import { toast } from "react-toastify";
import { objects } from "../../core/__objects";
import QueryBuilderSavedTable from "./QueryBuilderSavedTable";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InsSpinner from "../../../../layout/components/spinner/InsSpinner";
import type { FieldOrGroup, ValueSource } from "@react-awesome-query-builder/core";

const MySwal = withReactContent(Swal);

type Props = {
  className: string;
};

interface FieldItem {
  displayName: string;
  isCustom: boolean;
  isUpdateable: boolean;
  length: string;
  name: string;
  picklistValues: [];
  type: string;
}

interface FieldItems extends Array<FieldItem> {}

interface Fields {
  [key: string]: FieldOrGroup;
}

const QueryBuilderSetup: FC<Props> = ({ className }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);
  const [object, setObject] = useState<string>("account");
  const [SOQLFormat, setSOQLFormat] = useState<any>();
  const [savingSpinner, setSavingSpinner] = useState<boolean>(false);
  const [fetchedQueries, setFetchedQueries] = useState<any[]>([]);
  const [tableReload, setTableReload] = useState<any>();
  const [fetchedFields, setFetchedFields] = useState<FieldItems>([]);
  const [qbConfig, setQbConfig] = useState<Config>({
    ...BootstrapConfig,
    fields: {}
  });

  // Update the Query Builder object when the fields have been fetched
  useEffect(() => {
    const updatedFields: Fields = {};
    fetchedFields.forEach((field) => {
      const fieldType = FieldTypeConvert(field.type);
      if (fieldType === "select") {
        const selectValues: any = [];
        field.picklistValues.forEach((item) => selectValues.push({ value: item, title: item }));
        updatedFields[field.name] = {
          label: field.displayName,
          type: fieldType,
          valueSources: ["value" as ValueSource],
          fieldSettings: {
            listValues: selectValues
          }
        };
      } else {
        updatedFields[field.name] = {
          label: field.displayName,
          type: fieldType,
          valueSources: ["value" as ValueSource]
        };
      }
    });

    setQbConfig((prevConfig) => ({
      ...prevConfig,
      fields: updatedFields,
      operators: {
        ...prevConfig.operators,
        equal: {
          ...prevConfig.operators.equal,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.EQUAL", defaultMessage: "Equal to" })
        },
        greater: {
          ...prevConfig.operators.greater,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.GREATER", defaultMessage: "Greater" })
        },
        greater_or_equal: {
          ...prevConfig.operators.greater_or_equal,
          label: intl.formatMessage({
            id: "QUERYBUILDER.OPERATOR.GREATER_OR_EQUAL",
            defaultMessage: "Greater or equal"
          })
        },
        less: {
          ...prevConfig.operators.less,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.LESS", defaultMessage: "Less" })
        },
        less_or_equal: {
          ...prevConfig.operators.less_or_equal,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.LESS_OR_EQUAL", defaultMessage: "Less or equal" })
        },
        multiselect_equals: {
          ...prevConfig.operators.multiselect_equals,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.MULTISELECT_EQUALS", defaultMessage: "Equal to" })
        },
        not_equal: {
          ...prevConfig.operators.not_equal,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.NOT_EQUAL", defaultMessage: "Not equal to" })
        },
        select_equals: {
          ...prevConfig.operators.select_equals,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.SELECT_EQUALS", defaultMessage: "Equal to" })
        },
        select_not_equals: {
          ...prevConfig.operators.select_not_equals,
          label: intl.formatMessage({ id: "QUERYBUILDER.OPERATOR.SELECT_NOT_EQUALS", defaultMessage: "Not equal to" })
        }
      }
    }));
  }, [fetchedFields, intl]);

  const fetchData = useCallback(
    async (obj: any) => {
      setFetchedFields([]);
      setLoading(true);
      await axios
        .get(`/schema/salesforce`)
        .then((response) => {
          const fields = response.data[obj];
          if (!fields?.length) {
            toast.error(intl.formatMessage({ id: "SETTINGS.SFDC.FIELDS.INIT_ERROR" }), toastSettings("error"));
            return;
          }
          toast.success(`Fields fetched successfully for ${obj}`, toastSettings("success"));
          setFetchedFields(fields);
        })
        .catch((error) => {
          if (error instanceof Error) {
            toast.error(error.message, toastSettings("error"));
          } else {
            toast.error("Unknown error occurred", toastSettings("error"));
          }
        })
        .finally(() => setLoading(false));
    },
    [intl]
  );

  const handleObjectSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (SOQLFormat && SOQLFormat !== "") {
      MySwal.fire({
        title: "Are you sure?",
        text: "It will delete the current query settings and load the new object fields.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, load the new object and delete query!",
        cancelButtonText: "No, keep the current query!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          MySwal.fire(
            "Query is deleted!",
            "The query has been deleted and we are loading the selected object.",
            "success"
          );
          setObject(e.target.value);
        } else {
          MySwal.fire("Cancelled", "The query is still active.", "info");
        }
      });
    } else {
      setObject(e.target.value);
    }
  };

  const handleSOQLFormat = (soqlFormat: any) => {
    setSOQLFormat(soqlFormat);
  };

  const handleSaveQuery = async () => {
    setSavingSpinner(true);
    const payload: any = {
      object_queries: {}
    };
    payload.object_queries[object.toLowerCase()] = SOQLFormat;

    try {
      await axios.post("/salesforce/settings/object_queries", payload);
      setSavingSpinner((prevState) => !prevState);
      setTableReload(Math.random());

      toast.success(intl.formatMessage({ id: "SETTINGS.QUERYBUILDER.SAVESUCCES" }), toastSettings("success"));
    } catch (error) {
      console.log(error);
      setSavingSpinner((prevState) => !prevState);
    }
  };

  const getQueries = async () => {
    setFetchedQueries((prevState: any) => []);
    await axios
      .get("/salesforce/settings/object_queries")
      .then((response) => {
        const fetchedQueries = response.data;
        if (Object.keys(fetchedQueries).length !== 0) {
          Object.keys(fetchedQueries).forEach((query: any) => {
            setFetchedQueries((prevState: any) => [...prevState, { object: query, query: fetchedQueries[query] }]);
          });
        }
      })
      .catch((error: any) => {
        console.log("error : ", error);
      });
  };

  const handleTableReload = () => {
    setTableReload(Math.random());
  };

  useEffect(() => {
    getQueries();
  }, [tableReload]);

  useEffect(() => {
    fetchData(object);
  }, [fetchData, object]);

  console.log("qbConfig : ", qbConfig);

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level1name="SIDEBAR.SUBHEAD.SFDC"
          level1link="/app/home"
          level2name="SIDEBAR.ITEM.QUERYBUILDER"
          instructions="SETTINGS.QUERYBUILDER.INSTRUCTIONS"
        />
      </div>

      <div className={`card ${className}`}>
        <div className="position-relative">
          {loading && (
            <div
              className="indicator-progress d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle zindex-tooltip"
              style={{ zIndex: "10" }}
            >
              <InsSpinner
                text={intl.formatMessage({
                  id: "QUERYBUILDER.FETCHINGFIELDS"
                })}
                opacity="1"
              />
            </div>
          )}
          <div className="row px-8">
            <div
              className="col-sm-8 col-lg-6 col-12 d-flex flex-column flex-sm-row align-items-center order-2 order-sm-1"
              style={{ gap: "1rem" }}
            >
              <h4 className="text-muted fw-semibold d-block">
                {intl.formatMessage({ id: "QUERYBUILDER.SELECTOBJECT" })}
              </h4>
              <div className="w-100 w-sm-auto py-xs-2">
                <div className="flex-help" style={{ display: "flex", alignItems: "center" }}>
                  <select className="form-select form-select-lg" onChange={(e) => handleObjectSelect(e)}>
                    {objects.map((object) => (
                      <option key={object.value} value={object.value}>
                        {object.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-6 col-12 text-end d-flex align-items-center justify-content-end mb-10 mb-sm-0 order-1 order-sm-2">
              <button
                type="button"
                className={`btn btn-primary w-100 w-sm-auto ${SOQLFormat ? "" : "disabled"}`}
                onClick={handleSaveQuery}
              >
                {!savingSpinner && (
                  <span className="indicator-label">{intl.formatMessage({ id: "SETTINGS.QUERYBUILDER.SAVE" })}</span>
                )}
                {savingSpinner && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                    {intl.formatMessage({ id: "SETTINGS.QUERYBUILDER.SAVING" })}{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className="row px-8">
            <div className="col-12 p-0">
              <div className="querybuilder-rules card-body pt-2 columns-break-lg-1 columns-break-md-1 columns-break-sm-1">
                <QueryBuilder config={qbConfig} SOQLFormat={handleSOQLFormat} />
              </div>
            </div>
          </div>
        </div>
        <div className="row px-8">
          <div className="col-12">
            <h2 className="text-muted fw-semibold d-block">
              {intl.formatMessage({ id: "QUERYBUILDER.QUERIES.SAVED" })}
            </h2>
            <QueryBuilderSavedTable fetchedQueriesData={fetchedQueries} tableReload={handleTableReload} />
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryBuilderSetup;
