import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";

// Apps
import { I18nProvider } from "./localization/i18n";
import "./assets/keenicons/duotone/style.css";
import "./assets/keenicons/outline/style.css";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './assets/css/style.rtl.css'
 **/
import "./style/style.scss";
import "./style/plugins.scss";
import "./style/style.react.scss";
import "react-datepicker/dist/react-datepicker.css";

import { AppRoutes } from "./pages/routing/AppRoutes";
import { AuthProvider } from "./pages/auth";
import { RBACProvider } from "./pages/roles-permissions/core/RBACProvide";
import TagManager from "react-gtm-module";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject  interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || "",
};
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <I18nProvider>
        <AuthProvider>
          <RBACProvider>
            <AppRoutes />
          </RBACProvider>
        </AuthProvider>
      </I18nProvider>
    </QueryClientProvider>
  );
}
// import './index'
